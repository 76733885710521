import React, { FC } from 'react';
import classNames from 'classnames';
import objectHash from 'object-hash';

import { Col, Container, Row } from 'layout';
import Caption from 'common/Caption';
import LinkItem from 'common/LinkItem';
import { commonColorHandler } from 'utils/colorsHandler';

import ImageItem from './ImageItem';
import { VideoAndImageWithTextProps } from './models';
import VideoItem from './VideoItem';

import './VideoAndImageWithText.scss';

const VideoAndImageWithText: FC<VideoAndImageWithTextProps> = ({ module }) => {
  const ariaId: string = objectHash(module);

  return (
    <section
      className={classNames(
        `video-and-image-with-text ${commonColorHandler(module.backgroundColor[0])}`,
        {
          'video-and-image-with-text--image-right': module.isImageOnRight,
          section: commonColorHandler(module.backgroundColor[0]) === '',
          'section-bg':
            commonColorHandler(module.backgroundColor[0]) === 'video-and-image-with-text--grey',
        }
      )}
    >
      <Container>
        <Row className="video-and-image-with-text__holder">
          <Col xl="6" md="12" sm="12" className="video-and-image-with-text__media col--no-gutters">
            {!module.video ? (
              <ImageItem image={module.image} ariaId={ariaId} />
            ) : (
              <VideoItem
                video={module.video}
                image={module.image}
                playButtonText={module.playButtonText}
                ariaId={ariaId}
              />
            )}
            {module.captionText ? (
              <Caption text={module.captionText} ariaId={ariaId} isRte />
            ) : null}
          </Col>
          <Col
            xl="6"
            md="12"
            sm="12"
            className="video-and-image-with-text__content col--no-gutters"
          >
            {module.pretitle ? (
              <h3 className="video-and-image-with-text__pretitle caption">{module.pretitle}</h3>
            ) : null}
            {module?.title ? (
              <h2 className="video-and-image-with-text__title h4">{module.title}</h2>
            ) : null}
            {module.description ? (
              <p className="video-and-image-with-text__description">{module.description}</p>
            ) : null}
            <LinkItem
              link={module.ctaLink}
              linkClass="video-and-image-with-text__link btn btn--pink"
              titleClass="video-and-image-with-text__link-text"
              hasIcon
              isLink
              showTitle
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VideoAndImageWithText;
